/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { DynamoType } from "../../system/DynamoType";
import type { ProjectInstance } from "../project/Instance";
import type { UserOrganization } from "../user/Organization";

type Path = {
	type: "Path";
};

@HewSync.Type({
	scope: "synapse",
	name: "Folder",
	table: "user",
	prefix: "FOLDER"
})
export class SynapseFolder extends DynamoType {
	public static readonly type = "SynapseFolder";
	public readonly type = "SynapseFolder";

	@HewSync.Field({ reference: "Organization", type: "ID" })
	public readonly organization: HewSync.ID<UserOrganization>;

	@HewSync.Field({ reference: "Instance", type: "ID" })
	public readonly instance: HewSync.ID<ProjectInstance>;

	@HewSync.Field({ prefix: "FOLDER", type: "[String]", createOnly: true })
	public readonly path: string[];

	@HewSync.Field({ prefix: "FOLDER", type: "String", createOnly: true })
	public readonly name: string;

	private static cache = new Map<string, SynapseFolder>();

	public static get(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		this.initSubscriptions();

		let key = this.getKey(organization, instance, path, name);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new SynapseFolder({
				organization: organization.value,
				instance: instance.value,
				path: path,
				name: name,
				createdAt: "",
				updatedAt: ""
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("organization", "ID", organization.value),
					new HewSync.QueryVariable("instance", "ID", instance.value),
					new HewSync.QueryVariable("path", "[String]", path),
					new HewSync.QueryVariable("name", "String", name)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		return HewSync.queryGet<typeof SynapseFolder, SynapseFolder>(
			SynapseFolder,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("instance", "ID", instance.value),
				new HewSync.QueryVariable("path", "[String]", path),
				new HewSync.QueryVariable("name", "String", name)
			],
			false
		);
	}

	public static list(
		filter?: Partial<SynapseFolder> | ((data: SynapseFolder) => boolean)
	): HewSyncList<typeof SynapseFolder, SynapseFolder> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof SynapseFolder, SynapseFolder>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof SynapseFolder, SynapseFolder>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<SynapseFolder, "organization">>
	): HewSyncList<typeof SynapseFolder, SynapseFolder> {
		let list = new HewSyncList<typeof SynapseFolder, SynapseFolder>(this, undefined, params);

		return list;
	}

	public static create(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: {}
	) {
		this.initSubscriptions();
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];

		return HewSync.mutationCreate<typeof SynapseFolder, SynapseFolder>(SynapseFolder, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).instance = new HewSync.ID<any>(data.instance);
		(this as any).path = data.path;
		(this as any).name = data.name;
	}

	public get key() {
		return this.organization.value + "/" + this.instance.value + "/" + this.path.join("/") + "/" + this.name;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).path = data.path;
		(this as any).name = data.name;

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			SynapseFolder.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): SynapseFolder {
		return new SynapseFolder(data);
	}

	public static getKey(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		return organization.value + "/" + instance.value + "/" + path.join("/") + "/" + name;
	}

	public static getKeyFromData(data: { organization: string; instance: string; path: string[]; name: string }) {
		return `${data.organization + "/" + data.instance + "/" + data.path.join("/") + "/" + data.name}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
