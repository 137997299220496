/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { DynamoType } from "../../system/DynamoType";
import type { ProjectInstance } from "../project/Instance";
import type { UserOrganization } from "../user/Organization";
import { SynapseFolder } from "./Folder";

@HewSync.Type({
	scope: "synapse",
	name: "File",
	table: "user",
	parent: SynapseFolder,
	prefix: "FILE"
})
export class SynapseFile extends DynamoType {
	public static readonly type = "SynapseFile";
	public readonly type = "SynapseFile";

	@HewSync.Field({ reference: "Organization", type: "ID" })
	public readonly organization: HewSync.ID<UserOrganization>;

	@HewSync.Field({ reference: "Instance", type: "ID" })
	public readonly instance: HewSync.ID<ProjectInstance>;

	@HewSync.Field({ prefix: "FOLDER", type: "[String]", createOnly: true })
	public readonly path: string[];

	@HewSync.Field({ prefix: "FILE", type: "String", createOnly: true })
	public readonly name: string;

	@HewSync.Field({ type: "String" })
	public readonly uuid: string;

	@HewSync.Field({ type: "String", required: true })
	public readonly fileType: string;

	@HewSync.Field({ type: "String", default: '"pending"' })
	public readonly status: string;

	@HewSync.Field({ type: "String", default: '""' })
	public readonly size: string;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly metadata: any;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly config: any;

	@HewSync.Field({ type: "Boolean", default: "false" })
	public readonly shareable: boolean;

	private static cache = new Map<string, SynapseFile>();

	public static get(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		this.initSubscriptions();

		let key = this.getKey(organization, instance, path, name);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new SynapseFile({
				organization: organization.value,
				instance: instance.value,
				path: path,
				name: name,
				createdAt: "",
				updatedAt: "",
				uuid: "",
				fileType: "",
				status: "pending",
				size: "",
				metadata: "{}",
				config: "{}",
				shareable: false
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([
					new HewSync.QueryVariable("organization", "ID", organization.value),
					new HewSync.QueryVariable("instance", "ID", instance.value),
					new HewSync.QueryVariable("path", "[String]", path),
					new HewSync.QueryVariable("name", "String", name)
				]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.uuid = "";
		this.fileType = "";
		this.status = "pending";
		this.size = "";
		this.metadata = "{}";
		this.config = "{}";
		this.shareable = false;
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		return HewSync.queryGet<typeof SynapseFile, SynapseFile>(
			SynapseFile,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("instance", "ID", instance.value),
				new HewSync.QueryVariable("path", "[String]", path),
				new HewSync.QueryVariable("name", "String", name)
			],
			false
		);
	}

	public static list(
		filter?: Partial<SynapseFile> | ((data: SynapseFile) => boolean)
	): HewSyncList<typeof SynapseFile, SynapseFile> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof SynapseFile, SynapseFile>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof SynapseFile, SynapseFile>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<SynapseFile, "organization">>
	): HewSyncList<typeof SynapseFile, SynapseFile> {
		let list = new HewSyncList<typeof SynapseFile, SynapseFile>(this, undefined, params);

		return list;
	}

	public static fileDownload(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: {}
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];

		inputs.push(new HewSync.QueryVariable("kind", "String", "download"));

		return HewSync.queryFileDownload<typeof SynapseFile, SynapseFile>(SynapseFile, inputs);
	}

	public fileDownload(data: {}) {
		return SynapseFile.fileDownload(this.organization, this.instance, this.path, this.name, data);
	}

	public static preview(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: {}
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];

		inputs.push(new HewSync.QueryVariable("kind", "String", "preview"));

		return HewSync.queryFileDownload<typeof SynapseFile, SynapseFile>(SynapseFile, inputs);
	}

	public preview(data: {}) {
		return SynapseFile.preview(this.organization, this.instance, this.path, this.name, data);
	}

	public static create(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: {
			uuid?: string;
			fileType: string;
			status?: string;
			size?: string;
			metadata?: any;
			config?: any;
			shareable?: boolean;
		}
	) {
		this.initSubscriptions();
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];
		if (data.uuid !== undefined) {
			inputs.push(new HewSync.QueryVariable("uuid", "String", data.uuid));
		}
		if (data.fileType !== undefined) {
			inputs.push(new HewSync.QueryVariable("fileType", "String", data.fileType));
		}
		if (data.status !== undefined) {
			inputs.push(new HewSync.QueryVariable("status", "String", data.status));
		}
		if (data.size !== undefined) {
			inputs.push(new HewSync.QueryVariable("size", "String", data.size));
		}
		if (data.metadata !== undefined) {
			inputs.push(new HewSync.QueryVariable("metadata", "AWSJSON", JSON.stringify(data.metadata)));
		}
		if (data.config !== undefined) {
			inputs.push(new HewSync.QueryVariable("config", "AWSJSON", JSON.stringify(data.config)));
		}
		if (data.shareable !== undefined) {
			inputs.push(new HewSync.QueryVariable("shareable", "Boolean", data.shareable));
		}
		return HewSync.mutationCreate<typeof SynapseFile, SynapseFile>(SynapseFile, inputs);
	}

	public static update(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: {
			uuid?: string;
			fileType?: string;
			status?: string;
			size?: string;
			metadata?: any;
			config?: any;
			shareable?: boolean;
		},
		target?: SynapseFile
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];
		if (data.uuid !== undefined) {
			inputs.push(new HewSync.QueryVariable("uuid", "String", data.uuid));
		}
		if (data.fileType !== undefined) {
			inputs.push(new HewSync.QueryVariable("fileType", "String", data.fileType));
		}
		if (data.status !== undefined) {
			inputs.push(new HewSync.QueryVariable("status", "String", data.status));
		}
		if (data.size !== undefined) {
			inputs.push(new HewSync.QueryVariable("size", "String", data.size));
		}
		if (data.metadata !== undefined) {
			inputs.push(new HewSync.QueryVariable("metadata", "AWSJSON", JSON.stringify(data.metadata)));
		}
		if (data.config !== undefined) {
			inputs.push(new HewSync.QueryVariable("config", "AWSJSON", JSON.stringify(data.config)));
		}
		if (data.shareable !== undefined) {
			inputs.push(new HewSync.QueryVariable("shareable", "Boolean", data.shareable));
		}
		return HewSync.mutationUpdate<typeof SynapseFile, SynapseFile>(SynapseFile, inputs, target);
	}

	public update(data: {
		uuid?: string;
		fileType?: string;
		status?: string;
		size?: string;
		metadata?: any;
		config?: any;
		shareable?: boolean;
	}) {
		return SynapseFile.update(this.organization, this.instance, this.path, this.name, data, this);
	}

	public static remove(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		target?: SynapseFile
	) {
		return HewSync.mutationRemove<typeof SynapseFile, SynapseFile>(
			SynapseFile,
			[
				new HewSync.QueryVariable("organization", "ID", organization.value),
				new HewSync.QueryVariable("instance", "ID", instance.value),
				new HewSync.QueryVariable("path", "[String]", path),
				new HewSync.QueryVariable("name", "String", name)
			],
			target
		);
	}

	public remove() {
		return SynapseFile.remove(this.organization, this.instance, this.path, this.name, this);
	}

	public static duplicate(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: { organization?: HewSync.ID; instance?: HewSync.ID; path?: string[]; name?: string }
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];
		if (data.organization !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetOrganization", "ID", data.organization.value));
		}
		if (data.instance !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetInstance", "ID", data.instance.value));
		}
		if (data.path !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetPath", "[String]", data.path));
		}
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetName", "String", data.name));
		}
		return HewSync.mutationDuplicate<typeof SynapseFile, SynapseFile>(SynapseFile, inputs);
	}

	public duplicate(data: { organization?: HewSync.ID; instance?: HewSync.ID; path?: string[]; name?: string }) {
		return SynapseFile.duplicate(this.organization, this.instance, this.path, this.name, data);
	}

	public static updateKey(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: { organization?: HewSync.ID; instance?: HewSync.ID; path?: string[]; name?: string },
		target?: SynapseFile
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];
		if (data.organization !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetOrganization", "ID", data.organization.value));
		}
		if (data.instance !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetInstance", "ID", data.instance.value));
		}
		if (data.path !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetPath", "[String]", data.path));
		}
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("targetName", "String", data.name));
		}
		return HewSync.mutationUpdateKey<typeof SynapseFile, SynapseFile>(SynapseFile, inputs);
	}

	public updateKey(data: { organization?: HewSync.ID; instance?: HewSync.ID; path?: string[]; name?: string }) {
		return SynapseFile.updateKey(this.organization, this.instance, this.path, this.name, data, this);
	}

	public static fileUpload(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string,
		data: { contentType: string },
		target?: SynapseFile
	) {
		let inputs = [
			new HewSync.QueryVariable("organization", "ID", organization.value),
			new HewSync.QueryVariable("instance", "ID", instance.value),
			new HewSync.QueryVariable("path", "[String]", path),
			new HewSync.QueryVariable("name", "String", name)
		];
		inputs.push(new HewSync.QueryVariable("contentType", "String", data.contentType));
		return HewSync.mutationFileUpload<typeof SynapseFile, SynapseFile>(SynapseFile, inputs, target);
	}

	public fileUpload(data: { contentType: string }) {
		return SynapseFile.fileUpload(this.organization, this.instance, this.path, this.name, data, this);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).organization = new HewSync.ID<any>(data.organization);
		(this as any).instance = new HewSync.ID<any>(data.instance);
		(this as any).path = data.path;
		(this as any).name = data.name;
		(this as any).uuid = data.uuid;
		(this as any).fileType = data.fileType;
		(this as any).status = data.status;
		(this as any).size = data.size;
		(this as any).metadata = JSON.parse(data.metadata);
		(this as any).config = JSON.parse(data.config);
		(this as any).shareable = data.shareable;
	}

	public get key() {
		return this.organization.value + "/" + this.instance.value + "/" + this.path.join("/") + "/" + this.name;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).path = data.path;
		(this as any).name = data.name;
		(this as any).uuid = data.uuid;
		(this as any).fileType = data.fileType;
		(this as any).status = data.status;
		(this as any).size = data.size;
		(this as any).metadata = JSON.parse(data.metadata);
		(this as any).config = JSON.parse(data.config);
		(this as any).shareable = data.shareable;

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			SynapseFile.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): SynapseFile {
		return new SynapseFile(data);
	}

	public static getKey(
		organization: HewSync.ID<UserOrganization>,
		instance: HewSync.ID<ProjectInstance>,
		path: string[],
		name: string
	) {
		return organization.value + "/" + instance.value + "/" + path.join("/") + "/" + name;
	}

	public static getKeyFromData(data: { organization: string; instance: string; path: string[]; name: string }) {
		return `${data.organization + "/" + data.instance + "/" + data.path.join("/") + "/" + data.name}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
