/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseOrganization } from "../base/Organization";

@HewSync.Type({
	scope: "user",
	name: "Organization",
	table: "user",
	prefix: "ORGANIZATION"
})
export class UserOrganization extends BaseOrganization {
	public static readonly type = "UserOrganization";
	public readonly type = "UserOrganization";

	@HewSync.Field({ createOnly: true, generated: "util.autoId()", type: "ID" })
	public override readonly id: HewSync.ID<UserOrganization>;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly settings: any;

	@HewSync.Field({ default: "{}", type: "JSON" })
	public readonly config: any;

	private static cache = new Map<string, UserOrganization>();

	public static get(id: HewSync.ID<UserOrganization>) {
		this.initSubscriptions();

		let key = this.getKey(id);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new UserOrganization({
				id: id.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				description: "",
				settings: "{}",
				config: "{}"
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([new HewSync.QueryVariable("id", "ID", id.value)]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.description = "";
		this.settings = "{}";
		this.config = "{}";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(id: HewSync.ID<UserOrganization>) {
		return HewSync.queryGet<typeof UserOrganization, UserOrganization>(
			UserOrganization,
			[new HewSync.QueryVariable("id", "ID", id.value)],
			false
		);
	}

	public static list(
		filter?: Partial<UserOrganization> | ((data: UserOrganization) => boolean)
	): HewSyncList<typeof UserOrganization, UserOrganization> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof UserOrganization, UserOrganization>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof UserOrganization, UserOrganization>(this, filter);

			return list;
		}
	}

	public static listBy(
		params?: Partial<Pick<UserOrganization, "id">>
	): HewSyncList<typeof UserOrganization, UserOrganization> {
		let list = new HewSyncList<typeof UserOrganization, UserOrganization>(this, undefined, params);

		return list;
	}

	public static create(data: { name: string; description?: string; settings?: any; config?: any }) {
		let inputs = [];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.description !== undefined) {
			inputs.push(new HewSync.QueryVariable("description", "String", data.description));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		if (data.config !== undefined) {
			inputs.push(new HewSync.QueryVariable("config", "AWSJSON", JSON.stringify(data.config)));
		}
		return HewSync.mutationCreate<typeof UserOrganization, UserOrganization>(UserOrganization, inputs);
	}

	public static update(
		id: HewSync.ID<UserOrganization>,
		data: { name?: string; description?: string; settings?: any; config?: any },
		target?: UserOrganization
	) {
		let inputs = [new HewSync.QueryVariable("id", "ID", id.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.description !== undefined) {
			inputs.push(new HewSync.QueryVariable("description", "String", data.description));
		}
		if (data.settings !== undefined) {
			inputs.push(new HewSync.QueryVariable("settings", "AWSJSON", JSON.stringify(data.settings)));
		}
		if (data.config !== undefined) {
			inputs.push(new HewSync.QueryVariable("config", "AWSJSON", JSON.stringify(data.config)));
		}
		return HewSync.mutationUpdate<typeof UserOrganization, UserOrganization>(UserOrganization, inputs, target);
	}

	public update(data: { name?: string; description?: string; settings?: any; config?: any }) {
		return UserOrganization.update(this.id, data, this);
	}

	public static remove(id: HewSync.ID<UserOrganization>, target?: UserOrganization) {
		return HewSync.mutationRemove<typeof UserOrganization, UserOrganization>(
			UserOrganization,
			[new HewSync.QueryVariable("id", "ID", id.value)],
			target
		);
	}

	public remove() {
		return UserOrganization.remove(this.id, this);
	}

	public static batchRemove(items: { id: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[User_OrganizationKey]", items)];
		return HewSync.mutationBatchRemove<typeof UserOrganization, UserOrganization>(UserOrganization, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).id = new HewSync.ID<any>(data.id);
		(this as any).name = data.name;
		(this as any).description = data.description;
		(this as any).settings = JSON.parse(data.settings);
		(this as any).config = JSON.parse(data.config);
	}

	public get key() {
		return this.id.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).description = data.description;
		(this as any).settings = JSON.parse(data.settings);
		(this as any).config = JSON.parse(data.config);

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			UserOrganization.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): UserOrganization {
		return new UserOrganization(data);
	}

	public static getKey(id: HewSync.ID<UserOrganization>) {
		return id.value;
	}

	public static getKeyFromData(data: { id: string }) {
		return `${data.id}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
