import { normalizeCode } from "./HewSyncUtils";
import type { HewSyncType } from "./HewSyncType";
import type { DynamoType } from "./DynamoType";
import { HewSyncSocket } from "./HewSyncSocket";
import { BasicEvent } from "@h4x/common";

export type HewSyncTypeClass = {
	// readonly primaryIndex: any;
	type: string;
};

type TypeConfig = {
	readonly scope: string;
	readonly name: string;
	readonly table: string;
	readonly prefix?: string;
	readonly parent?: typeof HewSyncType;
};

class DataEntry {
	constructor(public readonly name: string, protected target: typeof HewSyncType) {}
	public is(target: typeof HewSyncType) {
		return this.target === target;
	}
}

type DataFieldConfig = {
	readonly type: string;
	readonly required?: boolean;
	readonly reference?: string;
	readonly default?: string;
	readonly input?: string;
	readonly prefix?: string;
	readonly createOnly?: true;
	readonly generated?: string;
};

class DataField extends DataEntry {
	constructor(name: string, target: typeof HewSyncType, private config: DataFieldConfig) {
		super(name, target);
	}

	public asPrefix() {
		return this.config.prefix;
	}

	public get type() {
		return this.config.type;
	}

	public get input() {
		return this.config.input ?? this.config.type;
	}
}

export class HewSyncTypeConfig {
	public fields: { [K: string]: DataField } = {};
	public type?: TypeConfig;
	constructor(public readonly target: typeof HewSyncType & HewSyncTypeClass) {}

	get fieldsList() {
		return Object.values(this.fields);
	}

	public get name() {
		let scope = this.type.scope[0]!.toUpperCase() + this.type.scope.substring(1);
		let name = scope + "_" + this.type.name;
		return name;
	}
}

// custom types
export namespace HewSync {
	export const PermissionsMap: { [K: string]: string } = {
		ProjectInstance: "view:synapse",
		SynapseFile: "view:synapse",
		SynapseFolder: "view:synapse",
		SynapseSession: "view:synapse",
		UserAsset: "view:organization",
		UserEntity: "view:api",
		UserEntityRole: "view:api",
		UserEntityToken: "view:api",
		UserGroup: "view:user",
		UserGroupRole: "view:user",
		UserInvite: "view:organization",
		UserInviteAccount: "view:organization",
		UserInviteEmail: "view:organization",
		UserMember: "view:user",
		UserMemberGroup: "view:user",
		UserMemberRole: "view:user",
		UserOrganization: "view:organization",
		UserRole: "view:user"
	};

	export type SessionAttachment = {
		type: "File" | "Folder";
		value: string[];
	};

	export type SynapseMessage = {
		readonly id: string;
		readonly message: {
			readonly content: string;
			readonly createdAt: number;
			readonly createdBy: string;
		};
		readonly response: {
			readonly content: string;
			readonly createdAt: number;
			// ???
		} | null;
		readonly config: {
			OpenAI: {
				runID: string;
				status: "in_progress";
			};
		};
		readonly attachments: SessionAttachment[];
	};

	export type Permission = {
		readonly actions: string[];
		readonly path: string;
		readonly scope: string;
	};
}

export namespace HewSync {
	// let host: string = undefined!;
	let hostWS: string = undefined!;
	export let auth = undefined as string | undefined;
	let authResolve: ((value: string) => void) | undefined;
	export let authPromise = new Promise<string>((resolve) => {
		authResolve = resolve;
	});

	export let socket = new HewSyncSocket();
	export function onAuth(token: string, url: string) {
		if (auth === token) {
			return;
		}
		auth = token;

		configure(url);

		let host = url.replace("https://", "").replace("/graphql", "");
		hostWS = host.replace("appsync-api", "appsync-realtime-api");

		if (socket && socket.isConnected === false) {
			socket.connect(host, hostWS, auth);
		}
		authResolve?.(token);
	}

	let url: string | ((query: string, variables: any) => Promise<any>) | undefined = undefined;
	export function configure(newURL: string | ((query: string, variables: any) => Promise<any>), token?: string) {
		url = newURL;
		if (token !== undefined) {
			auth = token;
		}
		authResolve?.("local");
	}

	export class PermissionManager {
		private permissions: Map<string, HewSync.Permission[]> = new Map();
		private initialized = false;
		private debugMode = false;
		public readonly onUpdate = new BasicEvent<() => void>();
		constructor() {}

		public setPermissions(permissions: { [K: string]: HewSync.Permission[] }) {
			this.permissions.clear();
			for (let [key, value] of Object.entries(permissions)) {
				this.permissions.set(key, value);
			}

			this.initialized = true;
			this.onUpdate.execute();
		}

		public setDebugMode(value: boolean) {
			this.debugMode = value;
		}

		public check(action: string, organization: string) {
			if (!this.initialized) {
				return true;
			}

			if (this.debugMode && JSON.parse(localStorage.getItem("HEWMEN_PERM_SKIP") || "0") === "1") {
				return true;
			}

			let permissions = this.permissions.get(organization);
			if (permissions === undefined) {
				return false;
			}

			return permissions.some((x) => x.actions.includes(action) || x.actions.includes("*"));
		}
	}

	export let permissionManager = new PermissionManager();

	export class ID<T = unknown> {
		#type!: T;
		constructor(public readonly value: string) {}

		public toJSON() {
			return this.value;
		}

		public toString() {
			return this.value;
		}
	}

	export class Timestamp {
		constructor(public readonly value: string) {}

		public asDate() {
			return new Date(this.value);
		}

		public asTime() {
			return this.value;
		}

		public getTime() {
			return new Date(this.value).getTime();
		}

		public toString() {
			return this.value;
		}
	}

	export const types = new Map<typeof HewSyncType, HewSyncTypeConfig>();

	function getTypeData(target: typeof HewSyncType) {
		let data = types.get(target);
		if (!data) {
			data = new HewSyncTypeConfig(target as typeof HewSyncType & HewSyncTypeClass);
			types.set(target, data);
		}
		return data;
	}

	export function getFullTypeData(target: Function) {
		let data = getTypeData(target as typeof HewSyncType);

		let parent = Object.getPrototypeOf(target) as typeof HewSyncType;
		while (parent && parent !== Function.prototype) {
			let parentData = getTypeData(parent);
			data.fields = {
				...parentData.fields,
				...data.fields
			};
			parent = Object.getPrototypeOf(parent) as typeof HewSyncType;
		}
		return data;
	}

	export function Field<T extends DynamoType>(config: DataFieldConfig) {
		return function (target: T, propertyKey: string) {
			let cls = target.constructor as typeof HewSyncType;
			let data = getTypeData(cls);
			data.fields[propertyKey] = new DataField(propertyKey, cls, config);
		};
	}

	export function Type(config: TypeConfig) {
		return function (target: typeof HewSyncType & HewSyncTypeClass) {
			let data = getTypeData(target as typeof HewSyncType);
			data.type = config;
		};
	}

	// async function executeQuery(query: HewSyncQuery<any>) {
	async function executeQuery(type: string, text: string, variables: QueryVariable[]) {
		if (!url) {
			throw new Error("HewSync URL not configured");
		}

		let queryVariables = "";
		if (variables.length > 0) {
			queryVariables = `(\n${variables.map((x, i) => `\t$_${i}: ${x.type}!`).join(",\n")}\n)`;
		}

		let parsedQuery = normalizeCode`
		${type}${queryVariables} {
			output:${text}
		}
		`;
		let parsedVariables = Object.fromEntries(variables.map((it) => [`_${it.id}`, it.value]));

		/*
			{
				query: parsedQuery,
				variables: parsedVariables
			},
		*/

		await Promise.race([authPromise, new Promise((resolve) => setTimeout(resolve, 1000))]);

		if (url instanceof Function) {
			let res = await url(parsedQuery, parsedVariables);

			return res as {
				data: {
					output: any;
				};
				errors: {
					message: string;
				}[];
			};
		} else {
			if (!auth) {
				throw new Error("No auth token");
			}

			let res = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: auth
				},
				body: JSON.stringify({
					query: parsedQuery,
					variables: parsedVariables
				})
			});

			return (await res.json()) as {
				data: {
					output: any;
				};
				errors: {
					message: string;
				}[];
			};
		}
	}

	function getFunctionName(config: HewSyncTypeConfig, fn: string) {
		let functionName: string;
		if (config.type) {
			functionName = config.type.scope + "_" + config.type.name + "_" + fn;
		} else {
			throw new Error("Cannot mutationRemove without type config");
		}
		return functionName;
	}

	export class QueryVariable {
		public id = -1;
		constructor(public readonly name: string, public readonly type: string, public readonly value: any) {}
	}

	type InternalType<T> = {
		getKeyFromData(data: T): string;
		from(data: any): T;
		cache: Map<string, T & { apply: (data: any) => void; replace: (data: any) => void }>;
		onSubscriptionEvent: BasicEvent<(data: any) => void>;
	};

	async function internalInitSubscriptions<K extends typeof HewSyncType>(type: K & InternalType<HewSyncType>) {
		await HewSync.authPromise;

		await HewSync.socket.subscribe(
			HewSync.getFullTypeData(type),
			(data: any) => {
				let key = type.getKeyFromData(data);
				let item = type.cache.get(key)!;
				console.log("Trying to update", key, data, item);
				if (item !== undefined) {
					item.apply(data);
					console.log("Updated", item);
				}

				type.onSubscriptionEvent.execute(data);
			},
			(removed: any, added: any) => {
				let key = type.getKeyFromData(removed);
				let item = type.cache.get(key)!;
				if (item !== undefined) {
					item.replace(added);
					console.log("Replaced", item, added);
				}
			}
		);
	}

	export async function initSubscriptions<K extends typeof HewSyncType>(
		type: K,
		_event: BasicEvent<(data: any) => void>
	) {
		await internalInitSubscriptions(type as K & InternalType<HewSyncType>);
	}

	class HewSyncListQuery<T> {
		constructor(
			public readonly config: HewSyncTypeConfig,
			public readonly type: string,
			public readonly text: string,
			public readonly variables: QueryVariable[]
		) {}

		public async execute(): Promise<{ items: T[]; nextToken?: string }> {
			let result = await executeQuery(this.type, this.text, this.variables);
			if (result.errors) {
				if (result.errors[0]!.message !== "You are not authorized to make this call.") {
					console.error(
						`[HewSync] Unable to execute operation\nOperation: ${this.text}\nVariables: ${JSON.stringify(
							this.variables,
							null,
							2
						)}\nErrors:`,
						result.errors
					);
				}
				throw new Error(result.errors[0]!.message);
			}

			let output = result.data.output as {
				items: unknown[];
				nextToken?: string;
			};
			if (typeof output === "object" && output && "items" in output && Array.isArray(output?.items)) {
				return { items: output.items.map((it) => it as T), nextToken: (output.nextToken as string) ?? undefined };
			} else {
				throw new Error("Invalid output");
			}
		}
	}

	type HewSyncQueryType = HewSyncType & { setUpdating: (value: boolean) => void };

	export class HewSyncQuery<T> {
		constructor(
			public readonly config: HewSyncTypeConfig,
			public readonly target: HewSyncQueryType | true | false | undefined,
			public readonly type: string,
			public readonly text: string,
			public readonly variables: QueryVariable[]
		) {}

		public async execute(): Promise<T> {
			console.debug("[HewSyncQuery] Updating?:", this.target !== undefined);

			if (this.target !== undefined && this.target !== true && this.target !== false) {
				this.target.setUpdating(true);
			}
			let result = await executeQuery(this.type, this.text, this.variables);
			if (result.errors) {
				if (result.errors.length === 1 && result.errors[0]!.message === "Not found") {
					return undefined as T;
				}

				if (result.errors[0]!.message !== "You are not authorized to make this call.") {
					console.error(
						`[HewSync] Unable to execute operation\nOperation: ${this.text}\nVariables: ${JSON.stringify(
							this.variables,
							null,
							2
						)}\nErrors:`,
						result.errors
					);
				}
				throw new Error(result.errors[0]!.message);
			}
			let output = result.data.output as unknown;

			if (this.target === true || this.target === false) {
				let TargetClass = this.config.target as unknown as {
					from(data: any): HewSyncType;
					getKeyFromData(data: any): string;
					cache: Map<string, T>;
				};

				let key = TargetClass.getKeyFromData(output);
				output = TargetClass.from(output) as T;
				if (this.target === true) {
					if (!TargetClass.cache.has(key)) {
						TargetClass.cache.set(key, output);
					}
				}
				output.setLoaded();
			} else if (this.target !== undefined) {
				this.target.setUpdating(false);
				// return this.target as T;
			}
			return output as T;
		}
	}

	function normalizeAppSync<T>(input: TemplateStringsArray, ...args: (string | QueryVariable | QueryVariable[])[]) {
		let last = input[input.length - 1]!;
		let lastTabCount = 0;
		for (let i = last.length - 1; i >= 0; i--) {
			if (last[i] === "\t") {
				lastTabCount++;
			} else {
				break;
			}
		}

		let text = "";
		let variables = [];

		let id = 0;
		for (let i = 0; i < args.length; i++) {
			let arg = args[i]!;
			if (Array.isArray(arg)) {
				let outputs = [];
				for (let j = 0; j < arg.length; j++) {
					let arg2 = arg[j];
					if (arg2 instanceof QueryVariable) {
						variables.push(arg2);
						arg2.id = id++;
						outputs.push(`${arg2.name}: $_${arg2.id}`);
					}
				}
				(args[i] as any) = outputs.join(", ");
			} else if (arg instanceof QueryVariable) {
				variables.push(arg);
				arg.id = id++;
				(args[i] as any) = `${arg.name}: $_${arg.id}`;
			}
		}

		for (let i = 0; i < args.length + 1; i++) {
			let part = input[i]!;
			let start = i === 0 ? input[i]!.indexOf("\n") + 1 : 0;

			let j = start;
			let length = i < args.length ? part.length : part.length - lastTabCount - 1;

			let line = false;
			while (j < length) {
				// skip tabs
				if (part[j] === "\t") {
					let k;
					for (k = 0; k < lastTabCount; k++) {
						if (part[j + k] === "\n") {
							line = true;
							break;
						} else if (part[j + k] !== "\t") {
							console.log(part[j + k]);
							throw new Error("Invalid code C");
						}
					}
					j += k;
				}

				let end = part.indexOf("\n", j);
				if (end >= length) {
					end = -1;
				}

				if (end === -1) {
					line = true;
					break;
				}
				text += part.substring(j, end);
				text += "\n";

				j = end;
				j++;
			}

			if (line) {
				// if (line) {
				let tabs = 0;
				while (j < length) {
					if (part[j] === "\t") {
						tabs++;
						j++;
					} else {
						break;
					}
				}

				if (j < length) {
					text += "\t".repeat(tabs) + part.substring(j, length);
					if (i < args.length) {
						text += `${args[i] as string}`;
					}
				} else {
					if (i < args.length) {
						text += `${args[i] as string}`
							.split("\n")
							.map((x: string) => "\t".repeat(tabs) + x)
							.join("\n");
					}
				}
			} else {
				text += part.substring(j, length);
				if (i < args.length) {
					text += args[i];
				}
			}
		}

		// return new HewSyncQuery(config, target, type, text, variables);
		return [text, variables] as const;
	}
	const fieldTypes: { [K: string]: (name: string) => string } = {
		"[Permission]": (name) => `${name} { actions path scope }`,
		"[SessionAttachment]": (name) => `${name} { type value }`
	};

	export function prepareFields(fields: DataField[]) {
		let output = fields.map((x) => fieldTypes[x.type]?.(x.name) ?? x.name);
		output.push("removed");
		return output;
	}

	export function queryList<T extends typeof HewSyncType, V extends HewSyncType>(
		type: T,
		inputs: QueryVariable[]
	): HewSyncListQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "list");

		if (inputs.length > 0) {
			return new HewSyncListQuery(
				config,
				"query",
				...normalizeAppSync`
				${functionName}(${inputs}) {
					items {
						${prepareFields(config.fieldsList).join("\n")}
					}
					nextToken
				}
				`
			);
		} else {
			return new HewSyncListQuery(
				config,
				"query",
				...normalizeAppSync`
				${functionName} {
					items {
						${prepareFields(config.fieldsList).join("\n")}
					}
					nextToken
				}
				`
			);
		}
	}
	export function queryGet<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "get");

		return new HewSyncQuery(
			config,
			target,
			"query",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationCreate<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[]
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "create");

		return new HewSyncQuery(
			config,
			true,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationDuplicate<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[]
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "duplicate");

		return new HewSyncQuery(
			config,
			true,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationAttach<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[]
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "attach");

		return new HewSyncQuery(
			config,
			true,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationDetach<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "detach");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationBatchDetach<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "batchDetach");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationUpdate<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "update");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationUpdateKey<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V[]> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "updateKey");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationRemove<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "remove");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationBatchRemove<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "batchRemove");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationFileUpload<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<string> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "fileUpload");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs})
			`
		);
	}

	export function queryFileDownload<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[]
	): HewSyncQuery<string> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "fileDownload");

		return new HewSyncQuery(
			config,
			undefined,
			"query",
			...normalizeAppSync`
			${functionName}(${inputs})
			`
		);
	}

	export function mutationChatAddMessage<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "addMessage");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationChatPollResponse<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "pollResponse");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}

	export function mutationSendEmail<T extends typeof HewSyncType, V extends HewSyncQueryType>(
		type: T,
		inputs: QueryVariable[],
		target?: V
	): HewSyncQuery<V> {
		let config = getFullTypeData(type);
		let functionName: string = getFunctionName(config, "sendEmail");

		return new HewSyncQuery(
			config,
			target,
			"mutation",
			...normalizeAppSync`
			${functionName}(${inputs}) {
				${prepareFields(config.fieldsList).join("\n")}
			}
			`
		);
	}
}
