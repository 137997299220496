/* ****************
 * GENERATED CODE *
 **************** */
import { BasicEvent } from "@h4x/common";
import { HewSyncList } from "../../system/HewSyncList";
import { HewSync } from "../../system/HewSync";
import { BaseAccount } from "../base/Account";

@HewSync.Type({
	scope: "admin",
	name: "Account",
	table: "admin",
	prefix: "ACCOUNT"
})
export class Admin extends BaseAccount {
	public static readonly type = "Admin";
	public readonly type = "Admin";

	@HewSync.Field({ createOnly: true, type: "ID" })
	public override readonly id: HewSync.ID<Admin>;

	@HewSync.Field({ type: "String" })
	public readonly email: string;

	private static cache = new Map<string, Admin>();

	public static get(id: HewSync.ID<Admin>) {
		this.initSubscriptions();

		let key = this.getKey(id);
		let item = this.cache.get(key)!;

		if (item === undefined) {
			item = new Admin({
				id: id.value,
				createdAt: "",
				updatedAt: "",
				name: "",
				lastEmailChangeAt: "",
				lastPasswordChangeAt: "",
				lastLoginAt: "",
				email: ""
			});
			this.cache.set(key, item);

			void (async () => {
				await HewSync.authPromise;
				item.fetch([new HewSync.QueryVariable("id", "ID", id.value)]);
			})();
		}

		return item;
	}

	protected reset() {
		this.createdAt = "";
		this.updatedAt = "";
		this.name = "";
		this.lastEmailChangeAt = "";
		this.lastPasswordChangeAt = "";
		this.lastLoginAt = "";
		this.email = "";
	}

	/**
	 * Raw get function, bypasses cache
	 *
	 * Do not use this function on the frontend, use get instead
	 */
	public static rawGet(id: HewSync.ID<Admin>) {
		return HewSync.queryGet<typeof Admin, Admin>(Admin, [new HewSync.QueryVariable("id", "ID", id.value)], false);
	}

	public static list(filter?: Partial<Admin> | ((data: Admin) => boolean)): HewSyncList<typeof Admin, Admin> {
		if (typeof filter === "object") {
			let list = new HewSyncList<typeof Admin, Admin>(this, (data) => {
				let result = true;
				for (let key in filter) {
					if (Array.isArray(filter[key])) {
						if (filter[key].length !== data[key].length || !filter[key].every((x, i) => x === data[key][i])) {
							result = false;
							break;
						}
					} else if (filter[key] instanceof HewSync.ID) {
						if (data[key].value !== filter[key].value) {
							result = false;
							break;
						}
					} else if (data[key] !== filter[key]) {
						result = false;
						break;
					}
				}
				return result;
			});

			return list;
		} else {
			let list = new HewSyncList<typeof Admin, Admin>(this, filter);

			return list;
		}
	}

	public static listBy(params?: Partial<Pick<Admin, "id">>): HewSyncList<typeof Admin, Admin> {
		let list = new HewSyncList<typeof Admin, Admin>(this, undefined, params);

		return list;
	}

	public static create(
		id: HewSync.ID<Admin>,
		data: {
			name?: string;
			lastEmailChangeAt?: HewSync.Timestamp;
			lastPasswordChangeAt?: HewSync.Timestamp;
			lastLoginAt?: HewSync.Timestamp;
			email?: string;
		}
	) {
		this.initSubscriptions();
		let inputs = [new HewSync.QueryVariable("id", "ID", id.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.lastEmailChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastEmailChangeAt", "String", data.lastEmailChangeAt));
		}
		if (data.lastPasswordChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastPasswordChangeAt", "String", data.lastPasswordChangeAt));
		}
		if (data.lastLoginAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastLoginAt", "String", data.lastLoginAt));
		}
		if (data.email !== undefined) {
			inputs.push(new HewSync.QueryVariable("email", "String", data.email));
		}
		return HewSync.mutationCreate<typeof Admin, Admin>(Admin, inputs);
	}

	public static update(
		id: HewSync.ID<Admin>,
		data: {
			name?: string;
			lastEmailChangeAt?: HewSync.Timestamp;
			lastPasswordChangeAt?: HewSync.Timestamp;
			lastLoginAt?: HewSync.Timestamp;
			email?: string;
		},
		target?: Admin
	) {
		let inputs = [new HewSync.QueryVariable("id", "ID", id.value)];
		if (data.name !== undefined) {
			inputs.push(new HewSync.QueryVariable("name", "String", data.name));
		}
		if (data.lastEmailChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastEmailChangeAt", "String", data.lastEmailChangeAt));
		}
		if (data.lastPasswordChangeAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastPasswordChangeAt", "String", data.lastPasswordChangeAt));
		}
		if (data.lastLoginAt !== undefined) {
			inputs.push(new HewSync.QueryVariable("lastLoginAt", "String", data.lastLoginAt));
		}
		if (data.email !== undefined) {
			inputs.push(new HewSync.QueryVariable("email", "String", data.email));
		}
		return HewSync.mutationUpdate<typeof Admin, Admin>(Admin, inputs, target);
	}

	public update(data: {
		name?: string;
		lastEmailChangeAt?: HewSync.Timestamp;
		lastPasswordChangeAt?: HewSync.Timestamp;
		lastLoginAt?: HewSync.Timestamp;
		email?: string;
	}) {
		return Admin.update(this.id, data, this);
	}

	public static remove(id: HewSync.ID<Admin>, target?: Admin) {
		return HewSync.mutationRemove<typeof Admin, Admin>(
			Admin,
			[new HewSync.QueryVariable("id", "ID", id.value)],
			target
		);
	}

	public remove() {
		return Admin.remove(this.id, this);
	}

	public static batchRemove(items: { id: string }[]) {
		let inputs: HewSync.QueryVariable[] = [new HewSync.QueryVariable("items", "[Admin_AccountKey]", items)];
		return HewSync.mutationBatchRemove<typeof Admin, Admin>(Admin, inputs);
	}

	/* eslint-disable */
	protected constructor(data: any) {
		super(Symbol.for("HewSyncType::Internal::Create") as unknown as void);

		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).id = new HewSync.ID<any>(data.id);
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).email = data.email;
	}

	public get key() {
		return this.id.value;
	}

	protected apply(data: any) {
		(this as any).createdAt = new HewSync.Timestamp(data.createdAt);
		(this as any).updatedAt = new HewSync.Timestamp(data.updatedAt);
		(this as any).name = data.name;
		(this as any).lastEmailChangeAt = data.lastEmailChangeAt;
		(this as any).lastPasswordChangeAt = data.lastPasswordChangeAt;
		(this as any).lastLoginAt = data.lastLoginAt;
		(this as any).email = data.email;

		if (data.removed) {
			(this.removed as any) = true;
			this.onRemove.execute(this);
			Admin.cache.delete(this.key);
		} else {
			this.onUpdate.execute(this);
		}
	}
	/* eslint-enable */

	protected static from(data: any): Admin {
		return new Admin(data);
	}

	public static getKey(id: HewSync.ID<Admin>) {
		return id.value;
	}

	public static getKeyFromData(data: { id: string }) {
		return `${data.id}`;
	}

	private static onSubscriptionEvent = new BasicEvent<(data: any) => void>();

	private static subscriptionsInitialized = false;
	static initSubscriptions() {
		if (this.subscriptionsInitialized === false) {
			void HewSync.initSubscriptions(this, this.onSubscriptionEvent);
			this.subscriptionsInitialized = true;
		}
	}
}
